import React from 'react'
import { CardFooterActions } from '../../components/styles'
import { CommentIcon } from '../comments/CommentIcon'
import { LikeTypes } from '@commonstock/common/src/api/like'
import LikeIcon from '../likes/LikeIcon'
import ShareIcon from '../share/ShareIcon'
import { TradeGroupFeedItem } from '@commonstock/common/src/api/feed'
import { destructTradeFeedItem } from '../trade/utils'
import { TradeDetailLink } from '../trade/TradeCard'

function TradeGroupFooterActions({ tradeFeedItem, detail }: { tradeFeedItem: TradeGroupFeedItem; detail?: boolean }) {
  const { tradeGroup, user } = destructTradeFeedItem(tradeFeedItem)

  if (!tradeGroup || !user) return null
  return (
    <CardFooterActions>
      {detail ? (
        <CommentIcon count={tradeGroup.comments?.count} />
      ) : (
        <TradeDetailLink tradeFeedItem={tradeFeedItem}>
          <CommentIcon count={tradeGroup.comments?.count} />
        </TradeDetailLink>
      )}
      <LikeIcon
        likes={tradeGroup.likes}
        parentUuid={tradeFeedItem.uuid}
        parentType={LikeTypes.TRADE_GROUP}
        authorUuid={user.uuid}
      />
      {/* @TODO: use trade group prop when BE ready */}
      <div />
      <ShareIcon tradeGroupFeedItem={tradeFeedItem} disabled={user.private} />
    </CardFooterActions>
  )
}

export default TradeGroupFooterActions
