import React from 'react'
import { CardFooterActions } from '../../components/styles'
import { CommentIcon } from '../comments/CommentIcon'
import LikeIcon from '../likes/LikeIcon'
import { Likes, LikeTypes } from '@commonstock/common/src/api/like'
import { SpacerV } from '../../composing/Spacing'
import { PostFeedItem } from '@commonstock/common/src/api/feed'
import { destructPostFeedItem } from './utils'
import { PostDetailLink } from './PostCard'
import ShareIcon from '../share/ShareIcon'
import { CardLayoutTypes, isUpvoteLayout } from 'src/scopes/feed/constants'
import ContentCardActions from 'src/scopes/content/ContentCardActions'
import { isLoggedIn } from '@commonstock/common/src/auth'
import RepostButton from 'src/components/repost/RepostButton'
import { useFlags } from 'src/scopes/feature-flags/useFlags'
import { Expander } from 'src/composing/Flex'
import LikeList from 'src/components/LikeList'
import { useTheme } from 'src/theme/ThemeContext'

function PostFooterActions({
  feedItem,
  detail,
  cardLayout,
  likes
}: {
  feedItem: PostFeedItem
  detail?: boolean
  cardLayout?: CardLayoutTypes
  likes?: Likes
}) {
  const { isSmallMobile } = useTheme()
  const { webRepostButton } = useFlags()
  let { post, user } = destructPostFeedItem(feedItem)
  if (!post || !user) return null
  return (
    <>
      <SpacerV />
      <CardFooterActions cardLayout={cardLayout}>
        {!isUpvoteLayout(cardLayout) && (
          <LikeIcon
            likes={post.likes}
            parentUuid={post.uuid}
            parentType={LikeTypes.POST}
            authorUuid={user.uuid}
            cardLayout={cardLayout}
          />
        )}
        {detail ? (
          <CommentIcon count={post.comments?.count} cardLayout={cardLayout} />
        ) : (
          <PostDetailLink postFeedItem={feedItem} cardLayout={cardLayout}>
            <CommentIcon count={post.comments?.count} cardLayout={cardLayout} />
          </PostDetailLink>
        )}
        {webRepostButton && isLoggedIn() && (
          <RepostButton
            post
            uuid={post.uuid}
            disabled={user.private}
            cardLayout={cardLayout}
            username={user.username}
            user={user}
          />
        )}
        {!isUpvoteLayout(cardLayout) && !webRepostButton && <Expander />}
        <ShareIcon postFeedItem={feedItem} disabled={user.private} cardLayout={cardLayout} />
        {!isSmallMobile && isUpvoteLayout(cardLayout) && !webRepostButton && <Expander />}
        {isUpvoteLayout(cardLayout) && detail && isLoggedIn() && likes && (
          <LikeList likes={likes} uuid={post.uuid} small />
        )}
        {isUpvoteLayout(cardLayout) && isLoggedIn() && <ContentCardActions feedItem={feedItem} />}
      </CardFooterActions>
    </>
  )
}

export default PostFooterActions
