export enum OgImageApiPath {
  TRADE = '/og/trade/',
  TRADE_GROUP = '/og/tradeGroup/',
  MEMO = '/og/memo/',
  POST = '/og/post/',
  PROFILE = '/og/profile/'
}

export enum OgImageNamePath {
  TRADE = '/trade.png',
  TRADE_GROUP = '/tradeGroup.png',
  MEMO = '/memo.png',
  POST = '/post.png',
  PROFILE = '/profile.png'
}
