import { useEffect } from 'react'
import { track } from '../mixpanel'
import { ShareType } from '@commonstock/common/src/api/link'
import { useFeedType } from 'src/scopes/feed/utils'

type ShareLinkGeneratedObject = {
  type: ShareType
  uuid: string
  ownerUuid?: string
}

export function useTrackShareLinkGenerated(data: ShareLinkGeneratedObject) {
  const { feedType } = useFeedType()
  useEffect(() => {
    const type = Object.keys(ShareType).find(k => ShareType[k as keyof typeof ShareType] === data.type)
    type &&
      track('Share Link Generated', {
        owner_uuid: data.ownerUuid,
        item_type: type,
        item_uuid: data.uuid,
        feed_type: feedType
      })
  }, [data, feedType])
}
