import React, { MouseEvent, useCallback, useMemo } from 'react';
import Tooltip from '../../components/Tooltip';
import { Share } from '../../theme/NewIcons';
import { CardIcon, Menu, MenuItem } from '../../components/styles';
import { IconButton } from '../../composing/CSButton';
import { useEphemeralModal } from '../modal/Modal';
import { TextCaptionEmphasis } from 'src/composing/CSText';
import { SpacerH } from 'src/composing/Spacing';
import { CardLayoutTypes, isUpvoteLayout } from 'src/scopes/feed/constants';
import { flexCentered, relative } from 'src/theme/AtomicClasses';
import dynamic from 'next/dynamic';
import { isLoggedIn } from '@commonstock/common/src/auth';
import { JoinModalPublic } from 'src/scopes/public/JoinModalPublic';
import useLoggedOutModal from 'src/scopes/public/useLoggedOutModal';
import { useState } from 'react';
import { Routes } from 'src/scopes/nav/constants';
import useEventListener from '@commonstock/common/src/utils/useEventListener';
import { css } from '@linaria/core';
import { MemoFeedItem, PostFeedItem, TradeFeedItem, TradeGroupFeedItem } from '@commonstock/common/src/api/feed';
const TradeShareModal = dynamic(async () => {
  const module = await import('./ShareModal');
  return module.TradeShareModal;
});
const TradeGroupShareModal = dynamic(async () => {
  const module = await import('./ShareModal');
  return module.TradeGroupShareModal;
});
const MemoShareModal = dynamic(async () => {
  const module = await import('./ShareModal');
  return module.MemoShareModal;
});
const PostShareModal = dynamic(async () => {
  const module = await import('./ShareModal');
  return module.PostShareModal;
});
type Props = {
  tradeFeedItem?: TradeFeedItem;
  tradeGroupFeedItem?: TradeGroupFeedItem;
  memoFeedItem?: MemoFeedItem;
  postFeedItem?: PostFeedItem;
  disabled?: boolean;
  cardLayout?: CardLayoutTypes;
};

function ShareIcon({
  tradeFeedItem,
  tradeGroupFeedItem,
  memoFeedItem,
  postFeedItem,
  disabled,
  cardLayout
}: Props) {
  const modal = useEphemeralModal();
  const loggedOutModal = useLoggedOutModal(`Share`);
  const [isOpen, setIsOpen] = useState(false);
  const [wasCopied, setWasCopied] = useState(false);
  const tip = useMemo(() => {
    if (disabled) return 'Share disabled';
    if (tradeGroupFeedItem) return 'Share trades';
    if (tradeFeedItem) return 'Share trade';
    if (memoFeedItem) return 'Share memo';
    if (postFeedItem) return 'Share post';
    return '';
  }, [disabled, tradeGroupFeedItem, tradeFeedItem, memoFeedItem, postFeedItem]);
  const onClickSharePreview = useCallback((e: MouseEvent) => {
    e.preventDefault();

    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    const shareModal = tradeFeedItem ? <TradeShareModal tradeFeedItem={tradeFeedItem} /> : memoFeedItem ? <MemoShareModal memoFeedItem={memoFeedItem} /> : tradeGroupFeedItem ? <TradeGroupShareModal tradeGroupFeedItem={tradeGroupFeedItem} /> : postFeedItem ? <PostShareModal postFeedItem={postFeedItem} /> : null;

    if (shareModal) {
      modal(shareModal);
      setIsOpen(false);
    }

    e.stopPropagation();
  }, [tradeFeedItem, memoFeedItem, tradeGroupFeedItem, postFeedItem, loggedOutModal, modal]);
  const onClickMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isLoggedIn()) {
      loggedOutModal('Join', <JoinModalPublic />);
      return;
    }

    setIsOpen(prev => !prev);
  }, [loggedOutModal]);
  const onClickCopy = useCallback((e: MouseEvent) => {
    e.preventDefault();
    const shareLink = tradeFeedItem ? Routes.tradeDetail(tradeFeedItem.uuid) : memoFeedItem ? Routes.memoDetail(memoFeedItem.uuid) : tradeGroupFeedItem ? Routes.tradeGroupDetail(tradeGroupFeedItem.uuid) : postFeedItem ? Routes.postDetail(postFeedItem.uuid) : '';
    navigator.clipboard.writeText(`${window.location.origin}${shareLink}`);
    setWasCopied(true);
  }, [memoFeedItem, postFeedItem, tradeFeedItem, tradeGroupFeedItem]);
  useEventListener('click', (e: any) => {
    if (!e.target.closest('#share-content-menu')) {
      setIsOpen(false);
      setWasCopied(false);
    }
  });
  return <CardIcon id="#share-content-menu">
      <Tooltip tip={tip} disable={isOpen}>
        <div className={relative}>
          <IconButton className={flexCentered} onClick={disabled ? undefined : onClickMenu} disabled={disabled} aria-label={'Share content'}>
            <Share size={1.25} />
            {isUpvoteLayout(cardLayout) && <>
                <SpacerH rem={0.5} />
                <TextCaptionEmphasis>Share</TextCaptionEmphasis>
              </>}
          </IconButton>
          <Menu className={wrapperPadding} hidden={!isOpen}>
            <MenuItem onClick={onClickCopy} isPositive={wasCopied}>
              {wasCopied ? 'Link copied!' : 'Copy link'}
            </MenuItem>
            <MenuItem onClick={onClickSharePreview}>Share preview</MenuItem>
          </Menu>
        </div>
      </Tooltip>
    </CardIcon>;
}

export default ShareIcon;
const wrapperPadding = "w7ki1ga";

require("../../../.linaria-cache/packages/oxcart/src/scopes/share/ShareIcon.linaria.module.css");